// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aksjonsplan-js": () => import("./../../../src/pages/aksjonsplan.js" /* webpackChunkName: "component---src-pages-aksjonsplan-js" */),
  "component---src-pages-bli-medlem-js": () => import("./../../../src/pages/bli-medlem.js" /* webpackChunkName: "component---src-pages-bli-medlem-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fellesgode-deltagere-js": () => import("./../../../src/pages/fellesgode-deltagere.js" /* webpackChunkName: "component---src-pages-fellesgode-deltagere-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medlemmer-js": () => import("./../../../src/pages/medlemmer.js" /* webpackChunkName: "component---src-pages-medlemmer-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-signup-success-js": () => import("./../../../src/pages/signup-success.js" /* webpackChunkName: "component---src-pages-signup-success-js" */),
  "component---src-pages-styret-js": () => import("./../../../src/pages/styret.js" /* webpackChunkName: "component---src-pages-styret-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

